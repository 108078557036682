import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/layout/partials/PageLoader';

import Base from './components/layout/Base';
import BasePage from './components/layout/BasePage';
import PrivateRoute from './components/app/PrivateRoute';

import routes from './routing/routes';
import componentLoader from './ComponentLoader';
import { ErrorBoundary, PermissionChecker } from './components/app';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

const Dashboard = lazy(() => componentLoader(() => import('./containers/Dashboard/Dashboard')));

// Agenda
const Appointement = lazy(() => componentLoader(() => import('./containers/Agenda/Appointment/Appointment')));
const Conflict = lazy(() => componentLoader(() => import('./containers/Agenda/Conflict/Conflict')));
const DayAppointment = lazy(() => componentLoader(() => import('./containers/Agenda/DayAppointment/DayAppointment')));
const GroupOverview = lazy(() => componentLoader(() => import('./containers/Agenda/Group/GroupOverview')));
const History = lazy(() => componentLoader(() => import('./containers/Agenda/History/History')));
const HomeVisit = lazy(() => componentLoader(() => import('./containers/Agenda/HomeVisit/HomeVisit')));
const MyAgenda = lazy(() => componentLoader(() => import('./containers/Agenda/MyAgenda/MyAgenda')));
const Note = lazy(() => componentLoader(() => import('./containers/Agenda/Note/Note')));
const SearchFreeSlot = lazy(() => componentLoader(() => import('./containers/Agenda/SearchFreeSlot/SearchFreeSlot')));

// Patients
const Patient = lazy(() => componentLoader(() => import('./containers/Patient/Patient/Patient')));

// Settings
const Agenda = lazy(() => componentLoader(() => import('./containers/Settings/Agenda/Agenda')));
const AppointmentStatus = lazy(() => componentLoader(() => import('./containers/Settings/AppointmentStatus/AppointmentStatus')));
const Block = lazy(() => componentLoader(() => import('./containers/Settings/Block/Block')));
const Category = lazy(() => componentLoader(() => import('./containers/Settings/Category/Category')));
const Exception = lazy(() => componentLoader(() => import('./containers/Settings/Exception/Exception')));
const Form = lazy(() => componentLoader(() => import('./containers/Settings/Form/Form')));
const Group = lazy(() => componentLoader(() => import('./containers/Settings/Group/Group')));
const Holiday = lazy(() => componentLoader(() => import('./containers/Settings/Holiday/Holiday')));
const Link = lazy(() => componentLoader(() => import('./containers/Settings/Link/Link')));
const OpenAppointmentMoment = lazy(() => componentLoader(() => import('./containers/Settings/OpenAppointmentMoment/OpenAppointmentMoment')));
const Openinghour = lazy(() => componentLoader(() => import('./containers/Settings/Openinghour/Openinghour')));
const PaymentLink = lazy(() => componentLoader(() => import('./containers/Settings/Payment/Link')));
const Schedule = lazy(() => componentLoader(() => import('./containers/Settings/Schedule/Schedule')));
const Subcategory = lazy(() => componentLoader(() => import('./containers/Settings/Subcategory/Subcategory')));
const Subgroup = lazy(() => componentLoader(() => import('./containers/Settings/Subgroup/Subgroup')));
const Tag = lazy(() => componentLoader(() => import('./containers/Settings/Tag/Tag')));
const Workspace = lazy(() => componentLoader(() => import('./containers/Settings/Workspace/Workspace')));

// Communication
const Campaign = lazy(() => componentLoader(() => import('./containers/Communication/Mailing/Campaign')));
const CampaignList = lazy(() => componentLoader(() => import('./containers/Communication/Mailing/List/MailingList')));
const Mail = lazy(() => componentLoader(() => import('./containers/Communication/Mail/Mail')));
const MailBox = lazy(() => componentLoader(() => import('./containers/Communication/Mailbox/Mailbox')));
const Member = lazy(() => componentLoader(() => import('./containers/Communication/Mailing/Member/Member')));
const Popup = lazy(() => componentLoader(() => import('./containers/Communication/Popup/Popup')));
const Sms = lazy(() => componentLoader(() => import('./containers/Communication/Sms/Sms')));
const Task = lazy(() => componentLoader(() => import('./containers/Communication/Task/Task')));
const Reminder = lazy(() => componentLoader(() => import('./containers/Communication/Reminder/Reminder')));
const Flow = lazy(() => componentLoader(() => import('./containers/Communication/Flow/Flow')));

// Pages
const Login = lazy(() => componentLoader(() => import('./containers/Pages/Login')));
const ForgotPassword = lazy(() => componentLoader(() => import('./containers/Pages/ForgotPassword')));
const ResetPassword = lazy(() => componentLoader(() => import('./containers/Pages/ResetPassword')));
const NotFound = lazy(() => componentLoader(() => import('./containers/Pages/NotFound')));
const NoAccess = lazy(() => componentLoader(() => import('./containers/Pages/NoAccess')));
const Forbidden = lazy(() => componentLoader(() => import('./containers/Pages/Forbidden')));
const ErrorPage = lazy(() => componentLoader(() => import('./containers/Pages/Error500')));

const Print = lazy(() => componentLoader(() => import('./containers/Pages/Print')));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    /* See full project for reference */
    routes.auth.login,
    routes.auth.forgotpassword,
    routes.auth.resetpassword,
    routes.auth.twofactor
    //routes.auth.resetpasswordcode
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'
    const animationName = ''; // 'rag-fadeIn'

    let isPage = false;
    listofPages.forEach(path => {
        if (location.pathname.indexOf(path) > -1) {
            isPage = true;
        }
    });

    if (
        // listofPages.indexOf(location.pathname) > -1 ||
        isPage ||
        location.pathname.includes('/print/') === true ||
        location.pathname.includes('/e/') === true
    ) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route path={routes.e.al} component={waitFor(Login)} />

                        {/* See full project for reference */}
                        <Route path={routes.auth.resetpasswordcode} component={waitFor(ResetPassword)} />
                        <Route path={routes.auth.resetpassword} component={waitFor(ResetPassword)} />
                        <Route path={routes.auth.forgotpassword} component={waitFor(ForgotPassword)} />
                        <Route path={routes.auth.login} component={waitFor(Login)} />

                        <PrivateRoute path={routes.appointments.print} component={waitFor(Print)} />
                        <PrivateRoute path={routes.agenda.print.week} component={waitFor(Print)} />
                        <PrivateRoute path={routes.agenda.print.day} component={waitFor(Print)} />
                        <PrivateRoute path={routes.agenda.print.group} component={waitFor(Print)} />
                        <PrivateRoute path={routes.homevisits.printDay} component={waitFor(Print)} />
                    </Switch>
                </Suspense>
            </BasePage>
        )
    } else {
        return (
            // Layout component wrapper
            <Base>
                <ErrorBoundary>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <Suspense fallback={<PageLoader />}>
                                <PermissionChecker location={location}>
                                    <Switch location={location}>
                                        <PrivateRoute exact path={routes.home} component={waitFor(Dashboard)} />
                                        <PrivateRoute exact path={routes.dashboard} component={waitFor(Dashboard)} />

                                        <PrivateRoute path={routes.agenda.client} component={waitFor(MyAgenda)} />
                                        <PrivateRoute path={routes.agenda.group} component={waitFor(GroupOverview)} />
                                        <PrivateRoute path={routes.agenda.subgroup} component={waitFor(GroupOverview)} />
                                        <PrivateRoute path={routes.homevisits.overview} component={waitFor(HomeVisit)} />

                                        <PrivateRoute path={routes.appointments.copy} exact component={waitFor(MyAgenda)} />
                                        <PrivateRoute path={routes.appointments.move} exact component={waitFor(MyAgenda)} />
                                        <PrivateRoute path={routes.appointments.moveClient} exact component={waitFor(GroupOverview)} />
                                        <PrivateRoute path={routes.agenda.searchfreeslot} component={waitFor(SearchFreeSlot)} />
                                        <PrivateRoute path={routes.agenda.history} component={waitFor(History)} />
                                        <PrivateRoute path={routes.agenda.conflicts} component={waitFor(Conflict)} />

                                        <PrivateRoute path={routes.appointments.overview} component={waitFor(Appointement)} />
                                        <PrivateRoute path={routes.dayappointments.overview} component={waitFor(DayAppointment)} />
                                        <PrivateRoute path={routes.agenda.notes.overview} component={waitFor(Note)} />
                                        <PrivateRoute path={routes.patients.overview} component={waitFor(Patient)} />

                                        <PrivateRoute path={routes.settings.agenda.overview} component={waitFor(Agenda)} />
                                        <PrivateRoute path={routes.settings.appointmentstatuses.overview} component={waitFor(AppointmentStatus)} />
                                        <PrivateRoute path={routes.settings.blocks.overview} component={waitFor(Block)} />
                                        <PrivateRoute path={routes.settings.categories.overview} component={waitFor(Category)} />
                                        <PrivateRoute path={routes.settings.exceptions.overview} component={waitFor(Exception)} />
                                        <PrivateRoute path={routes.settings.forms.overview} component={waitFor(Form)} />
                                        <PrivateRoute path={routes.settings.group.overview} component={waitFor(Group)} />
                                        <PrivateRoute path={routes.settings.holidays.overview} component={waitFor(Holiday)} />
                                        <PrivateRoute path={routes.settings.links.overview} component={waitFor(Link)} />
                                        <PrivateRoute path={routes.settings.openappointmentmoment.overview} component={waitFor(OpenAppointmentMoment)} />
                                        <PrivateRoute path={routes.settings.openinghour.overview} component={waitFor(Openinghour)} />
                                        <PrivateRoute path={routes.settings.payments.links.overview} component={waitFor(PaymentLink)} />
                                        <PrivateRoute path={routes.settings.schedules.overview} component={waitFor(Schedule)} />
                                        <PrivateRoute path={routes.settings.subcategories.overview} component={waitFor(Subcategory)} />
                                        <PrivateRoute path={routes.settings.subgroups.overview} component={waitFor(Subgroup)} />
                                        <PrivateRoute path={routes.settings.tags.overview} component={waitFor(Tag)} />
                                        <PrivateRoute path={routes.settings.workspaces.overview} component={waitFor(Workspace)} />

                                        <PrivateRoute path={routes.communication.tasks.overview} component={waitFor(Task)} />
                                        <PrivateRoute path={routes.communication.reminders.overview} component={waitFor(Reminder)} />
                                        <PrivateRoute path={routes.communication.popups.overview} component={waitFor(Popup)} />
                                        <PrivateRoute path={routes.communication.mailbox.inbox} component={waitFor(MailBox)} />
                                        <PrivateRoute path={routes.communication.mailing.list.member.overview} component={waitFor(Member)} />
                                        <PrivateRoute path={routes.communication.mailing.list.overview} component={waitFor(CampaignList)} />
                                        <PrivateRoute path={routes.communication.mailing.campaign.overview} component={waitFor(Campaign)} />
                                        <PrivateRoute path={routes.communication.mail.compose} component={waitFor(Mail)} />
                                        <PrivateRoute path={routes.communication.sms.compose} component={waitFor(Sms)} />
                                        <PrivateRoute path={routes.communication.flow.overview} component={waitFor(Flow)} />

                                        <PrivateRoute path={routes.error} component={waitFor(ErrorPage)} />
                                        <PrivateRoute path={routes.noaccess} component={waitFor(NoAccess)} />
                                        <PrivateRoute path={routes.forbidden} component={waitFor(Forbidden)} />
                                        <PrivateRoute path={routes.notfound} component={waitFor(NotFound)} />

                                        <Redirect exact from={routes.myagenda} to={routes.agenda.client} />
                                        <Redirect exact from={routes.subgroup} to={routes.agenda.subgroup} />
                                        <Redirect exact from={routes.searchfreeslot} to={routes.agenda.searchfreeslot} />
                                        <Redirect exact from={routes.conflicts} to={routes.agenda.conflicts} />
                                        <Redirect exact from={routes.history} to={routes.agenda.history} />
                                        <Redirect exact from={routes.groupoverview} to={routes.agenda.group} />

                                        <Redirect to={routes.notfound} />
                                    </Switch>
                                </PermissionChecker>
                            </Suspense>
                        </CSSTransition>
                    </TransitionGroup>
                </ErrorBoundary>
            </Base>
        )
    }
};

export default withRouter(Routes);
